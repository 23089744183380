import React from "react";

import Layout from "components/layout/layoutPanel";
import Panel from "components/common/Panel";
import TileCard from "components/common/TileCard-new";
import SEO from "components/Seo";

import panelData from "constants/homepage/panel-new";
import tileCardData from "constants/homepage/tile-card-new";
import HomepageHeroBanner from "components/cards/HomepageHeroBanner-new";
import HomeProductHighlight from "components/cards/HomeProductHighlight";
import AwardsInfo from "components/cards/AwardsInfo";
import { Svg } from 'kh-common-components';
import heroSkateboard from 'images/moving-house/hero-skateboard-boxes.png';

// style
// bespoke styles for this page
// https://www.gatsbyjs.com/docs/how-to/styling/css-modules
import 'styles/pages/homepage-new.css';

function Homepage() {

    return (
        <>
            <SEO title="Kleenheat"
                description="We're WA's truly local natural gas supplier. Enjoy award-winning, local customer service and great ongoing value with Kleenheat." />

            <div style={{ 'overflowX': 'hidden' }}>
                <HomepageHeroBanner />
                <Layout theme="mer-theme--light"
                    padding="mer-pt-lg mer-pb-sm feature-links"
                    inlineStyle={{ 'background': 'rgba(0,158,222,0.25)' }}
                    id="homepage-tile-card">
                    <div className="container mer-pt-de">
                        <div className="row mer-panel align-items-center justify-content-around flex-direction-column flex-direction-md-row">
                            <div className="col-12 text-center">
                                <h4 className="my-0">Looking for something else?</h4>
                            </div>
                            <TileCard data={tileCardData} />
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-8 text-md-center">
                                <a className="d-inline-block mer-p-sm hero-notice" href="/lpg">
                                    <div className="d-flex align-items-center justify-content-center text-left">
                                        <Svg title="Alert"
                                            icon="alert-jazzy"
                                            aria-hidden="true"
                                            style={{ 'color': 'inherit', 'width': '1.5em', 'height': '1.5em' }} />
                                        <h6 className="my-0 mer-pl-sm" style={{ 'color': 'inherit' }}>Service changes for LPG customers. <span className="mer-text--no-wrap">Click here to learn more.</span></h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </Layout>
            </div>

            {/* Why choose kleenheat */}
            <HomeProductHighlight panelBackground="mer-bg--ui-light"
                panelPadding="mer-pt-lg" />

            {/* Moving house made easy */}
            <Layout background="mer-bg--ui-light"
                padding="mer-py-ju">
                <div className="container">
                    <div className="row mer-panel feature-pill">
                        <div className="col-10 col-xl-5 col-md-6 col-sm-7 content mer-py-lg text-center mer-text--size-lg">
                            <h2>Moving house made easy</h2>
                            <p className="mer-color--primary-royal">New to Kleenheat? Simply tell us where you're moving and we'll take care of the rest. If you're an existing customer you can move your gas online through <a href="/my-account">My Account</a>.</p>
                            <div className="mer-panel__actions mer-button-group justify-content-center">
                                <a href="/moving-house" title="Move now" className="mer-button mer-button--primary" target="">Move your gas today</a>
                            </div>
                        </div>
                        <div className="col-6 col-xl-5 col-lg-4 col-md-5 col-sm-3 mer-panel__images feature-pill-asset ">
                            <img className="mer-panel__image"
                                src={heroSkateboard}
                                alt="Moving house made easy"
                            />
                        </div>
                    </div>
                </div>
            </Layout>

            <AwardsInfo
                panelBackground="mer-bg--ui-light"
                panelPadding="mer-pb-lg"
            />

            <Layout background="mer-bg--ui-light" theme="mer-theme--light" padding="mer-py-lg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-10 col-sm-8 col-lg-6 mer-text--size-lg">
                            <div className="content text-center">
                                <h2 className="mer-color--primary-cyan">Other things we do</h2>
                            </div>
                        </div>
                        <div className="col-10 col-lg-8">
                            <Panel data={panelData} id="homepage1" />
                            <Panel data={panelData} id="homepage2" />
                            <Panel data={panelData} id="homepage3" />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}

export default Homepage;
